.subscribers{
    display: flex;
    flex-direction: column; 
    /* align-items: center; */
    justify-content: center; 
    color: #146C94;
}

.subscribers .load-btns{
    align-self: start;
}

.users-info{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.users-info div{
    margin: 5px;
}

.users-decoders-grid{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.users-decoder{
    border: 1px solid #B0BEC5;
    color: #1565C0;
    background-color: #FFFFFF;
    border-radius: 5px;
    padding: 10px;
}

.users-decoder{
    width: 100%;
}
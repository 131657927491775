.modal-window{
    top: 0;
    left: 0;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}


.modal-box{
    background: #FFF;
    padding: 20px;
    border-radius: 10px;
}

.modal-btns{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 10px;
}


.edit-modal-box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex; 
    flex-direction: column;
    text-align: start;
}

.checkboxed{
    margin: 10px;
}

.autocomplete-input{
    left: 0;
    width: 93%;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #ced4da;
    outline: none;
}

.autocomplete-options{
    position: relative;
    /* top: 100%; */
    left: 0;
    width: 100%;
    border: 1px solid black;
    max-height: 100px;
}

.autocomplete-options option{
    text-align: center;
    border: 2px solid #ced4da;
}
.padBtn{
    margin: 5px;
}
.reportsHeader{
    display: flex;
    width: 50%; 
    align-items: end;
    margin-left: 10px;
    font-size: 20px;
}
.report-box {
    margin: 20px auto;
    padding: 20px;
    max-width: 95%;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .reportTotals {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
    .reportTotals td {
    padding: 10px;
    text-align: left;
  }
  
  .reportTotals tr:nth-child(odd) {
    background-color: #f9f9f9;
  }
  
  .reportTableBtns {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .reportTableBtns {
    width: 100%;
    padding: 12px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    text-align: center;
  }
  
  .reportTableBtns:hover {
    background-color: #0056b3;
  }
  


.xls{
    background-color: #f9f9f9;
    padding:12px 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.xlsIcon{
    width: 25px;
    margin-right: 10px;
}

.excels{
    margin-left: 10px;
}

.red{
    color: red;
    font-size: 20px;
    margin: 0;
    align-self: center;
}
/* ///////////////////////////////////////////////////////////////////////////////// */
/* page and header */
*{
    --first-colour: #F5F5F5;
}
.App{
    background-color: var(--first-colour);
    min-height: 100vh;
}
.box{
    padding: 10px 15px 0 15px;
}
.header {
    background: linear-gradient(90deg, #ffffff, #f1f5f9);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #BDBDBD;
  }
  
  .header .nav-link {
    color: #0D47A1;
    font-size: 16px;
    text-decoration: none;
    padding: 4px 8px;
    margin: 0 8px;
  }
  
  .header .nav-link:hover {
    text-decoration: underline;
    color: #1976D2;
  }
  
  .header .choosed-link {
    background-color: #E3F2FD;
    border-radius: 4px;
    color: #0D47A1;
  }

.nav-btns{
    display: flex; 
    align-items: center;
}

.links{
    text-decoration: none;
    font-size: 20px;
}

.center-box{
    text-align: center;
    margin: 5px;
}

.icon-img{
    width: 20px;
    height: auto;
    margin: 5px;
}

/* ///////////////////////////////////////////////////////////////////////////////// */
/* inputs */
.textField{
    padding: 20px;
    border: 0;
    border-radius: 10px;
    background-color: #ECEFF1;
    color: #1565C0;
    margin: 10px;
    outline: none;
    font-size: 15px;
}

.input-areas label{
    /* font-size: 14px; */
    color: #1565C0;
    position: relative;
    top: 10px;
    left: 12px;
    background: #F7F9FC;
    border-radius: 6px;
    padding: 0 5px;
}
.inputs {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 5px;
    font-size: 16px;
    border: 2px solid #B0BEC5;
    border-radius: 8px;
    background-color: #F7F9FC;
    transition: border-color 0.3s, box-shadow 0.3s;
  }
  
  .inputs:hover {
    border-color: #90A4AE;
  }
  
  .inputs:focus {
    border-color: #1565C0;
    box-shadow: 0 0 5px rgba(21, 101, 192, 0.5);
    outline: none;
  }
  .inputs::placeholder {
    color: #9E9E9E;
  }

/* ///////////////////////////////////////////////////////////////////////////////// */
/* Buttons */

.logout-btn{
    margin-left: 100px; text-align: center; text-decoration: none;
}

.logout-btn:hover{
    cursor: pointer;
}

button:hover{
    cursor: pointer;
}

.requestBtn {
    background-color: #1565C0;
    color: #FFFFFF;
    padding: 12px 24px;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, box-shadow 0.3s;
  }
  
  .requestBtn:hover {
    background-color: #0D47A1;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
  }

  .closeBtn {
    background-color:  #FFFFFF;
    color: #37474F;
    border: 1px solid #B0BEC5;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  .closeBtn:hover {
    background-color: #F5F9FF;
    color: #616161;
    border: 1px solid #BDBDBD;
  }
  

.disBtn{
    border: 0;
    border-radius: 5px;
    padding: 10px;
    font-size: 20px;
    background-color: gray;
    color: rgb(63, 63, 63);
}

.disBtn:hover{
    cursor: default;
    opacity: 1;
}

/* .closeBtn{
    border: 0;
    border-radius: 5px;
    padding: 10px;
    font-size: 20px;
    background-color: #e7e7e7;
    color: #146C94;
} */

.icon-in{
    margin-right: 5px;
    padding: 0;
}

.icon-in img{
    width: 40px;
    margin: 0;
    height: auto;
}

.cross-img {
    transform: rotate(45deg);
}



/* ///////////////////////////////////////////////////////////////////////////////// */
/* Pagination */

.pagination{
    display: flex;
    flex-direction: row;
    justify-content: end;
    list-style-type: none;
    cursor: pointer;
}

.pagination li{
    margin: 5px;
}

.pagination-link-btn{
    background-color: transparent;
    border: 0;
}


/* ///////////////////////////////////////////////////////////////////////////////// */
/* main tables */

  .subscribers table, .op-table table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 16px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #000000;
  }
  
  .subscribers th, .subscribers td,  .op-table  th, .op-table td {
    padding: 12px 15px;
    border-bottom: 1px solid #ddd;
  }
  
  .subscribers th, .op-table  th {
    background-color: #ECEFF1;
    color: #1565C0;
    font-weight: bold;
  }

  .subscribers tr:nth-child(even), .op-table tr:nth-child(even) {
    background-color: #F7F9FC;
  }
  

  .subscribers tr:hover, .op-table tr:hover {
    background-color: #E3F2FD;
  }

.offset-limit{
    display: flex;
    flex-direction: row;
    align-items: center;
}

/* spin modal*/
.load-modal{
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #1565C0;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
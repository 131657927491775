.rights-in{
    border: 1px solid #146C94;
    border-radius: 5px;
    text-align: center;
    padding: 5px;
    color: #146C94;
    font-weight: bold;
}

.op-table{
    width: 100%;
    padding: 5px;
}
.ticks{
    padding: 0;
}


.mux{
    margin-bottom: 10px;
}


.whole-mux{
    width: 50%;
    display: flex;
    justify-content: space-between;
}

.mux-container{
    width: 100%;
    max-width: 360px;
}

.mux-items-container{
    display: flex; 
    justify-content: space-between;
    margin: 10px 0;
    padding: 5px;
    border: 1px solid black;
    border-radius: 5px;
}

.left-container{
    display: flex;  
    align-items: center;
    /* margin-left: 10px; */
}


/* .constructor-table{
    width: 100%;
    text-align: center;
}
.constructor-table th{
    border: 1px solid black;
    border-radius: 5px;
}

.constructor-table .row-name{
    border: 1px solid black;
    border-radius: 5px;
}

.constructor-table .sub-col{
    display: inline-block;
    width: 33%;
    margin: 0;
}

.constructor-table .checkbox{
    text-align: center;
} */

.constructor-table {
    border-collapse: collapse;
    width: 100%;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
  /* .subs{
    display: flex;
    justify-content: space-between;
    padding: 0;
  } */
    .sub-col, .constructor-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  .constructor-table th {
    background-color: #f4f4f4;
    color: #333;
    font-weight: bold;
    border: 1px solid #ddd;
    padding: 8px 0;
    text-align: center;
  }
  
  .constructor-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  

.transp-btn {
    background-color: transparent;
    border: 0;
    border-radius: 5px;
    padding: 10px;
    font-size: 20px;
    color: #1565C0;
}
.programs h2, .programs h3 {
    /* font-size: 1.5rem; */
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;
    border-bottom: 2px solid #ddd;
    padding-bottom: 0.5rem;
  }
  

  .programs ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
  }
  
  .programs ul li {
    padding: 0.5rem;
    font-size: 1rem;
    border-bottom: 1px solid #f4f4f4;
  }
  
  .programs ul li:hover {
    background-color: #f0f8ff;
    color: #007bff;
    cursor: pointer;
  }
  